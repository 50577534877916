const es = {
  "landing.label.email": "Correo electrónico",
  "landing.label.name": "Nombre",
  "landing.label.password": "Contraseña",
  "landing.label.confirmPassword": "Confirmar contraseña",
  "landing.button.forgotPassword": "Olvidé mi contraseña",
  "landing.button.register": "Crear nueva cuenta",
  "landing.button.create": "Crear cuenta",
  "landing.button.back": "Volver",
  "landing.error.email": "Correo electrónico invalido",
  "landing.error.email.alreadyExists": "Este correo electronico ya posee una cuenta en el sistema",
  "landing.error.name": "Es necesario que nos indiques tu nombre",
  "landing.error.confirmPassword": "Las contraseñas no coinciden",
  "landing.error.system.failure": "Algo salio mal, intentalo denuevo mas tarde",
  "landing.error.invalidCredentials": "Credenciales inválidas",
  "private.nav.label.signout": "Salir",
  "private.sidebar.label.profile": "Mi perfil",
  "private.sidebar.label.home": "Inicio",
  "private.profile.button.changePassword": "Cambiar contraseña",
  "private.profile.label.id": "ID usuario",
  
  "general.button.cancel": "Cancelar",
  "general.button.save": "Guardar",
  "general.button.saving": "Guardando",
  "general.button.edit": "Editar",
  "general.button.saveChanges": "Guardar cambios",
  "general.toast.updated": "Los cambios se han guardado",
};

export default es;
