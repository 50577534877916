import React from "react";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import fontawesome from "@fortawesome/fontawesome";
import { faMoon, faSun, faCartPlus, faSearch }  from "@fortawesome/fontawesome-free-solid";

import LoginComponent from "./LoginComponent";
import RegisterComponent from "./RegisterComponent";
import RecoveryComponent from "./RecoveryComponent";
import CatalogComponent from "./CatalogComponent";
import { CatalogContextProvider } from "./catalog/CatalogContext";
import t, { I18nContext } from "../../i18n";

fontawesome.library.add(faMoon, faSun, faCartPlus, faSearch);

const theme = extendTheme({
  initialColorMode: 'light',
  styles: {
    global: {
      'html, body': {
        color: 'gray.600',
      }
    }
  }
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <CatalogComponent />,
  },
  {
    path: "/login",
    element: <LoginComponent />,
  },
  {
    path: "/register",
    element: <RegisterComponent />
  },
  {
    path: "/recovery",
    element: <RecoveryComponent />
  }
]);

interface LandingComponentProps {
  language?: string,
}

const LandingComponent = (props: LandingComponentProps) => {
  const language = props.language || "es";
  const i18n = t(language);
  return (
    <I18nContext.Provider value={ i18n }>
      <ChakraProvider theme={ theme }>
        <CatalogContextProvider>
          <RouterProvider router={ router } />
        </CatalogContextProvider>
      </ChakraProvider>
    </I18nContext.Provider>
  );
};

export default LandingComponent;
