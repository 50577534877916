import React from "react";

import es from "./es";

const map = {
  es: es,
};

const t = (language) => {
  const selection = map[language];
  return (query) => {
    const val = selection[query];
    if (!val) {
      return query;
    }
    return val;
  };
};

export default t;

export const I18nContext = React.createContext<any>({});

export const useI18nContext = (base?: string) => {
  const fn = React.useContext(I18nContext);
  if (base) {
    return (q) => fn(`${ base }.${ q }`);
  }
  return fn;
};
