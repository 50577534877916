import React from "react";
import {
  Flex,
  Button,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";

import { useI18nContext } from "../../../i18n";

const SidebarComponent = () => {
  const navigate = useNavigate();
  const t = useI18nContext("private");
  return (
    <Flex direction="column" rowGap="20px">
      <Button variant="ghost" textAlign="left" pl="20px" justifyContent="flex-start" onClick={ () => navigate("/") }>
        <FontAwesomeIcon icon="fa-solid fa-chart-area" className="button-icon" />
        { t("sidebar.label.home") }
      </Button>
      <Button variant="ghost" textAlign="left" pl="20px" justifyContent="flex-start" onClick={ () => navigate("/profile") }>
        <FontAwesomeIcon icon="fa-solid fa-user" className="button-icon" />
        { t("sidebar.label.profile") }
      </Button>
    </Flex>
  );
};

export default SidebarComponent;