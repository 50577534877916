import React from "react";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Button,
  Box,
  Flex,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import FlexCenter from "../shared/FlexCenter";
import LayoutComponent from "./layout/LayoutComponent";
import { useI18nContext } from "../../i18n";
import { loginAccount } from "../../services/landing";
import { useCustomForm } from "../shared/Form";

interface LoginComponentFormType {
  email: string,
  password: string,
  system: any,
}

const LoginComponent = () => {
  const navigate = useNavigate();
  const t = useI18nContext("landing");
  const { submit, form: { register, formState: { errors } } } = useCustomForm<LoginComponentFormType>({
    defaultValues: {
      email: "mario@mario.cl",
      password: null,
      system: null
    },
    submitFn: loginAccount,
    onSuccess: (_: any) => window.location = window.location
    ,
  });
  return (
    <LayoutComponent>
      <FlexCenter>
        <Flex direction="column" rowGap="6px" w="400px" className="landing-box">
          <FormControl isInvalid={ !!errors.system }>
            <FormErrorMessage>{ t(errors.system && errors.system.message) }</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={ !!errors.email }>
            <FormLabel>{ t("label.email") }</FormLabel>
            <Input type="email" { ...register("email", { required: true }) } />
            <FormErrorMessage>{ t(errors.email && errors.email.message || "label.email") }</FormErrorMessage>
          </FormControl>
          <FormControl marginBottom="20px" isInvalid={ !!errors.password }>
            <FormLabel>{ t("label.password") }</FormLabel>
            <Input type="password" { ...register("password", { required: true }) } />
            <FormErrorMessage>{ t(errors.password && errors.password.message || "error.invalidCredentials") }</FormErrorMessage>
          </FormControl>
          <Button colorScheme="blue" w="100%" onClick={ submit }>Ingresar</Button>
          <Button colorScheme="blue" w="100%" variant="link" h="40px" onClick={ () => navigate("/recovery")}>{ t("button.forgotPassword") }</Button>
          <Button colorScheme="blue" w="100%" variant="link" h="40px" onClick={ () => navigate("/register") }>{ t("button.register") }</Button>
        </Flex>        
      </FlexCenter>
    </LayoutComponent>
  );
};

export default LoginComponent;