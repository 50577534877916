import React from "react";

interface MoneyProps {
  value: number | string
}

const Money = (props: MoneyProps) => {
  const { value } = props;
  const asString: string = `${ value }`;
  let result: any = [];
  for (let i = 0; i < asString.length; i++) {
    if (i % 3 == 0 && i != 0) {
      result.push(".")
    }
    result.push(asString[asString.length - i - 1]);
  }
  result = result.reverse().join("");
  return (
    <>{ result }</>
  );
};

export default Money;