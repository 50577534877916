import { request } from "../base-request";

export default {};

interface createNewAccountProps {
  email: string,
  name: string,
  password: string,
}

interface Error {
  field: string,
  message: string,
}

interface createNewAccountResult {
  success: boolean,
  response?: any,
  errors: Error[]
}

export const createNewAccount = async (props: createNewAccountProps): Promise<createNewAccountResult> => {
  return await request({
    url: "/api/public/account/signup",
    method: "POST",
    data: props
  });
};