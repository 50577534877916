import React from "react";
import { 
  Flex,
  Text,
  Image,
  Button,
} from "@chakra-ui/react";

import Money from "../../shared/Money";

interface ProductCardComponentProps {
  name: string,
  price: string,
  description: string,
}

const ProductCardComponent = (props: ProductCardComponentProps) => {
  return (
    <Flex 
      w="calc(20% - 20px)" 
      p="10px" 
      direction="column" 
      border="1px solid" 
      borderRadius="4px"
      borderColor="gray.100"
    >
      <Image src="/image-placeholder.png" alt="product" w="100%" />
      <Text as="b" color="pink.300">{ props.name }</Text>
      <Text>$ <Money value={ props.price } /></Text>
      <Text fontSize="sm" mb="10px">{ props.description }</Text>
      <Button variant="outline" size="sm" color="pink.300">Ver detalle</Button>
    </Flex>
  );
};

export default ProductCardComponent;
