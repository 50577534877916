import React from "react";
import {
  Flex,
  Spinner,
  Button,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useI18nContext } from "../../i18n";

interface FormButtonsProps {
  save: Function,
  reset: Function,
  edit: Function,
  setEdit: Function,
  saving: boolean,
  success?: string, 
}

const FormButtons = (props: FormButtonsProps) => {
  const { save, edit, setEdit, reset, saving } = props;
  const t = useI18nContext();
  return (
    <Flex gap="20px" mt="10px" justifyContent="flex-end">
      <Flex className="form-message-success" alignItems="center" color="green">
        { !!props.success ?
          <>
            <FontAwesomeIcon icon="fa-solid fa-check" className="button-icon" />
            { props.success && t(props.success) }
          </> : undefined
        }
      </Flex>
      {
        edit ?
          <>
            <Button variant="solid" colorScheme="green" onClick={ save } isDisabled={ saving }>
              { saving ? 
                <>
                  <Spinner size="sm" mr="10px" />
                  { t("general.button.saving") }
                </> 
                : 
                <>
                  <FontAwesomeIcon icon="fa-solid fa-save" className="button-icon" />
                  { t("general.button.saveChanges") }
                </> 
              }
            </Button>
            <Button variant="outline" onClick={ reset }>
              { t("general.button.cancel") }
            </Button>
          </>
        :
          <Button variant="outline" onClick={ () => setEdit(true) }>
            <FontAwesomeIcon icon="fa-solid fa-edit" className="button-icon" />
            { t("general.button.edit") }
          </Button>
      }
    </Flex>
  );
};

export default FormButtons;
