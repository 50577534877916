export default {
  private: {
    navbar: {
      bg: {
        light: '#E2E8F0',
        dark: '#171923'
      }
    },
    layout: {
      bg: {
        light: 'transparent',
        dark: '#1A202C'
      }
    }
  }
};