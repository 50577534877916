import React from "react";
import {
  Box,
  Flex
} from "@chakra-ui/react";

import NavComponent from "./NavComponent";

interface LayoutComponentProps {
  children?: React.ReactNode[]
}

const LayoutComponent = (props: LayoutComponentProps) => {
  return (
    <Flex minH="100%" direction="column">
      <NavComponent />
      <Flex grow="1" direction="column">
        { props.children }
      </Flex>
    </Flex>
  );
};

export default LayoutComponent;