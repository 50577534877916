import React from "react";
import { 
  Flex, 
  Button,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Input,
  Image,
  useColorMode,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NavComponent = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  return (
    <Flex direction="column" alignItems="center" h="70px">
      <Flex grow="1" w="80%">
        <Flex w="200px" alignItems="center">
          <Image src="/pink-rose-logo.png" h="55px" />
        </Flex>
        <Flex grow="1"></Flex>
        
        <Flex grow="1" alignItems="center" justifyContent="flex-end" columnGap="10px">
          <InputGroup maxW="400px">
            <InputLeftElement pointerEvents='none'>
              <FontAwesomeIcon icon="fa-solid fa-search" />
            </InputLeftElement>
            <Input type='tel' placeholder="Buscar producto" />
            <InputRightElement width="4.5rem">
              <Button size="sm" mr="5px" color="pink.500">Buscar</Button>
            </InputRightElement>
          </InputGroup>
          <Button variant="ghost" color="pink.300">Tienda</Button>
          <Button variant="ghost" color="pink.300">Empresas</Button>
          <Button variant="ghost" color="pink.300">Equipo</Button>
          <Button variant="ghost" onClick={ toggleColorMode } color="pink.400">
            { colorMode == 'light' ? 
              <FontAwesomeIcon icon="fa-solid fa-moon" />
            :
              <FontAwesomeIcon icon="fa-solid fa-sun" />
            }
          </Button>
          <Button variant="outline" color="pink.300" borderColor="pink.300">
            <FontAwesomeIcon icon="fa-solid fa-cart-plus" className="button-icon" />
            Mi Carrito
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default NavComponent;