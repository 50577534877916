import axios from "axios";

const client = axios.create({
  baseURL: '/',
  timeout: 1500,
  headers: {},
});

const CSRFToken = (cookies) => {
  const splitCookies = cookies.split("; ");
  return splitCookies.find(cookie => cookie.startsWith("CSRF-TOKEN=")).split("=")[1];
};

export const request = async (params: any) => {
  const headers = params.headers || {};
  headers["X-CSRF-Token"] = CSRFToken(document.cookie);
  params.headers = headers;
  try {
    const result = await client.request(params);
    if (result.status == 200)
      return result.data;
    throw result;
  } catch (err) {
    console.error(err);
    return {
      success: false,
      errors: [
        { field: "system", message: "error.system.failure" }
      ]
    };
  }
};

export default {};