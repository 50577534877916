import React from "react";

interface CatalogContextState {
  products: any[],
}

const initialState: CatalogContextState = {
  products: [
    { name: "Product", price: 300000, description: "Foo bar some text description for the product" },
    { name: "Product", price: 8000, description: "Foo bar some text description for the product" },
    { name: "Product", price: 8000, description: "Foo bar some text description for the product" },
    { name: "Product", price: 8000, description: "Foo bar some text description for the product" },
    { name: "Product", price: 8000, description: "Foo bar some text description for the product" },
    { name: "Product", price: 8000, description: "Foo bar some text description for the product" },
    { name: "Product", price: 8000, description: "Foo bar some text description for the product" },
    { name: "Product", price: 8000, description: "Foo bar some text description for the product" },
    { name: "Product", price: 8000, description: "Foo bar some text description for the product" },
  ],
};

export const CatalogContext = React.createContext(initialState);

export const CatalogContextProvider = (props: { children: React.ReactNode }) => {
  const state: CatalogContextState = React.useState(initialState);
  return (
    <CatalogContext.Provider value={ state }>
      { props.children }
    </CatalogContext.Provider>
  );
};

export default {};
