import React from "react";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import fontawesome from "@fortawesome/fontawesome";
import { faMoon, faSun, faCartPlus, faSearch }  from "@fortawesome/fontawesome-free-solid";

import t, { I18nContext } from "../../i18n";

fontawesome.library.add(faMoon, faSun, faCartPlus, faSearch);

const theme = extendTheme({
  initialColorMode: 'light',
  styles: {
    global: {
      'html, body': {
        color: 'gray.600',
      }
    }
  }
});

export const ThemeProvider = (props: { children?: any, language?: string }) => {
  const language = props.language || "es";
  const i18n = t(language);
  return (
    <I18nContext.Provider value={ i18n }>
      <ChakraProvider theme={ theme }>
        { props.children }
      </ChakraProvider>
    </I18nContext.Provider>
  );
};

export default {};
