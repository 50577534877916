import React from "react";
import {
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Button,
  Spinner
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import LayoutComponent from "./layout/LayoutComponent";
import FlexCenter from "../shared/FlexCenter";

import { useI18nContext } from "../../i18n";
import { createNewAccount } from "../../services/landing";
import { useCustomForm } from "../shared/Form";

interface RegisterComponentFormType {
  email: string,
  name: string,
  password: string,
  confirmPassword: string,
  system: any,
}

const RegisterComponent = () => {
  const navigate = useNavigate();
  const t = useI18nContext("landing");
  const { submit, form: { register, formState: { errors } }, loading } = useCustomForm<RegisterComponentFormType>({
    defaultValues: {
      email: "mario@mario.cl",
      name: "Mario",
      password: "Mario123",
      confirmPassword: "Mario123",
      system: null, 
    },
    submitFn: createNewAccount
  });
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  const confirmPasswordValidate = React.useCallback((_, v) => {
    return v.password == v.confirmPassword;
  }, []);
  return (
    <LayoutComponent>
      <FlexCenter>
        <Flex direction="column" rowGap="6px" w="400px" className="landing-box">
          <FormControl isInvalid={ !!errors.system }>
            <FormErrorMessage>{ t(errors.system && errors.system.message) }</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={ !!errors.email } isDisabled={ loading }>
            <FormLabel>{ t("label.email") }</FormLabel>
            <Input type="email" { ...register("email", { required: true, pattern: emailPattern }) } />
            <FormErrorMessage>{ t(errors.email && errors.email.message || "error.email") }</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={ !!errors.name } isDisabled={ loading }>
            <FormLabel>{ t("label.name") }</FormLabel>
            <Input type="text" { ...register("name", { required: true }) } />
            <FormErrorMessage>{ t("error.name") }</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={ !!errors.password } isDisabled={ loading }>
            <FormLabel>{ t("label.password") }</FormLabel>
            <Input type="password" { ...register("password", { required: true }) } />
            <FormErrorMessage>{ t("error.password") }</FormErrorMessage>
          </FormControl>
          <FormControl mb="20px" isInvalid={ !!errors.confirmPassword } isDisabled={ loading }>
            <FormLabel>{ t("label.confirmPassword") }</FormLabel>
            <Input type="password" { ...register("confirmPassword", { validate: confirmPasswordValidate }) } />
            <FormErrorMessage>{ t("error.confirmPassword") }</FormErrorMessage>
          </FormControl>
          <Button colorScheme="blue" w="100%" onClick={ submit } isDisabled={ loading }>{ t("button.create") } { loading && <Spinner size="sm" ml="10px" /> }</Button>
          <Button colorScheme="blue" w="100%" variant="link" h="40px" onClick={ () => navigate("/login") }>{ t("button.back") }</Button>
        </Flex>
      </FlexCenter>
    </LayoutComponent>
  );
};

export default RegisterComponent;
