import React from "react";

import LayoutComponent from "./layout/LayoutComponent";

const IndexComponent = () => {
  return (
    <LayoutComponent>
      <div>This is index</div>
    </LayoutComponent>
  );
};

export default IndexComponent;