import React from "react";
import {
  Flex
} from "@chakra-ui/react";

interface FlexCenterProps {
  children?: React.ReactNode,
}

const FlexCenter = (props: FlexCenterProps) => {
  return (
    <Flex grow="1" alignItems="center" justifyContent="center">
      { props.children }
    </Flex>
  );
};

export default FlexCenter;
