import React from "react";

import { ThemeProvider } from "../shared/theme";

interface LandingComponentProps {
  language?: string,
}

const LandingComponent = (props: LandingComponentProps) => {
  return (
    <ThemeProvider { ...props }>
      This is IndexComponent        
    </ThemeProvider>
  );
};

export default LandingComponent;
