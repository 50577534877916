import { request } from "../base-request";

export const getProfile = async () => {
  return await request({
    url: "/api/private/account/profile",
    method: "GET"
  });
};

interface updateProfileProps {
  name: string,
}

export const updateProfile = async (props: updateProfileProps) => {
  return await request({
    url: "/api/private/account/profile",
    method: "POST",
    data: props
  });
};

export default {};

