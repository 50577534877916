import React from "react";
import {
  Flex,
  FormControl,
  FormLabel,
  Input,
  Button,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";

import LayoutComponent from "./layout/LayoutComponent";
import FlexCenter from "../shared/FlexCenter";

const RecoveryComponent = () => {
  const navigate = useNavigate();
  return (
    <LayoutComponent>
      <FlexCenter>
        <Flex direction="column" rowGap="6px" w="400px" className="landing-box">
          <FormControl mb="20px">
            <FormLabel>Correo electrónico</FormLabel>
            <Input type="email" />
          </FormControl>
          <Button colorScheme="blue" w="100%">Recuperar cuenta</Button>
          <Button colorScheme="blue" w="100%" variant="link" h="40px" onClick={ () => navigate("/login") }>Volver</Button>
        </Flex>
      </FlexCenter>
    </LayoutComponent>
  );
};

export default RecoveryComponent;
