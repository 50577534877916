import React from "react";
import {
  Flex,
  Button,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Input,
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import LayoutComponent from "./layout/LayoutComponent";
import ProductCardComponent from "./catalog/ProductCardComponent";
import { CatalogContext } from "./catalog/CatalogContext";

const CatalogComponent = () => {
  const [context, ] = React.useContext(CatalogContext);
  return (
    <LayoutComponent>
      <Flex grow="1" direction="column" alignItems="center">
        <Flex grow="1" direction="column" w="80%">

          {/* <Flex h="60px" alignItems="center" justifyContent="flex-end">
            <Flex grow="1"></Flex>
            <InputGroup maxW="400px">
              <InputLeftElement pointerEvents='none'>
                <FontAwesomeIcon icon="fa-solid fa-search" />
              </InputLeftElement>
              <Input type='tel' placeholder="Buscar producto" />
              <InputRightElement width="4.5rem">
                <Button size="sm" mr="5px">Buscar</Button>
              </InputRightElement>
            </InputGroup>
          </Flex> */}
          <Flex grow="1" mt="50px">
            <Flex w="200px" direction="column" rowGap="10px">
              <Button variant="ghost" justifyContent="flex-start" color="pink.300">Cuidado de la piel</Button>
              <Button variant="ghost" justifyContent="flex-start" color="pink.300">Maquillaje</Button>
              <Button variant="ghost" justifyContent="flex-start" color="pink.300">Suplementos</Button>
              <Button variant="ghost" justifyContent="flex-start" color="pink.300">Cuerpo</Button>
              <Button variant="ghost" justifyContent="flex-start" color="pink.300">Fragancias</Button>
            </Flex>
            <Flex pl="20px" grow="1" direction="column">
              <Flex wrap="wrap" rowGap="20px" columnGap="20px">
                { context.products.map((product) => <ProductCardComponent { ...product } />) }
              </Flex>
            </Flex>
          </Flex>

        </Flex>
      </Flex>
      
    </LayoutComponent>
  );
};

export default CatalogComponent;
