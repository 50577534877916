import React, { Children } from "react";
import { 
  Flex, 
  Box,
  useColorMode,
} from "@chakra-ui/react";

import SidebarComponent from "./SidebarComponent";
import NavComponent from "./NavComponent";

interface LayoutComponentProps {
  children: Children
}

const LayoutComponent = (props: LayoutComponentProps) => {
  const { colorMode } = useColorMode();
  return (
    <Flex direction="column" minH="100vh" bg={ `private.layout.bg.${ colorMode }` }>
      <NavComponent />
      <Flex grow="1">
        <Box w="200px" p="20px">
          <SidebarComponent /> 
        </Box>
        <Box width="100%" p="20px">
          { props.children }
        </Box>     
    </Flex>
    </Flex>
  );
};

export default LayoutComponent;
