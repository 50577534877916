import React from "react";
import { useForm, DefaultValues, Path } from "react-hook-form";

interface SubmitFnResponseError {
  field: string,
  message: string,
}

interface SubmitFnResponse {
  success: boolean,
  errors: SubmitFnResponseError[],
}

interface useCustomFormProps<T> {
  defaultValues: T,
  submitFn: (_: any) => Promise<SubmitFnResponse>,
  onSuccess?: Function,
  editing?: boolean,
  delay?: number,
}

export const useCustomForm = <T>(props: useCustomFormProps<T>) => {
  const f = useForm({ defaultValues: props.defaultValues as DefaultValues<T> });
  const [loading, setLoading] = React.useState<boolean>(false);
  const [editing, setEditing] = React.useState<boolean>(false);
  const submit = React.useCallback(async () => {
    f.clearErrors("system" as "root");
    const isValid = await f.trigger();
    if (isValid) {
      setLoading(true);
      const fields = Object.keys(props.defaultValues);
      let payload = {};
      for (const field of fields) {
        if (field != "system")
          payload[field] = f.getValues(field as Path<T>);
      }
      const response = await props.submitFn(payload);
      setTimeout(() => {
        if (!response.success) {
          for (const error of response.errors) {
            f.setError(error.field as "root", { type: "custom", message: error.message });
          }
        } else {
          setEditing(false);
          props.onSuccess && props.onSuccess(payload);
        }
        setLoading(false);
      }, props.delay || 0);
    }
  }, [f.formState, loading, setLoading]);
  return { submit, form: f, loading, setEditing, editing };
};

export default {};
