import React from "react";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import fontawesome from "@fortawesome/fontawesome";
import { 
  faDoorOpen, 
  faUser, 
  faSun, 
  faMoon, 
  faLaptop,
  faChartArea,
  faSave,
  faEdit,
  faCheck,
} from "@fortawesome/fontawesome-free-solid";

import IndexComponent from "./IndexComponent";
import Colors from "../shared/colors";
import t, { I18nContext } from "../../i18n";
import ProfileComponent from "./ProfileComponent";

fontawesome.library.add(faDoorOpen, faUser, faSun, faMoon, faLaptop, faChartArea, faSave, faEdit, faCheck);

const theme = extendTheme({
  initialColorMode: 'system',
  colors: Colors
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <IndexComponent />,
  },
  {
    path: "/profile",
    element: <ProfileComponent />,
  },
], {
  basename: '/app'
});

const PrivateComponent = (props: { language?: string } ) => {
  const language = props.language || "es";
  const i18n = t(language);
  return (
    <I18nContext.Provider value={ i18n }>
      <ChakraProvider theme={ theme }>
          <RouterProvider router={ router } />
      </ChakraProvider>
    </I18nContext.Provider>
  );
};

export default PrivateComponent;
