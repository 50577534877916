import React from "react";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Flex,
} from "@chakra-ui/react";

import LayoutComponent from "./layout/LayoutComponent"
import { useI18nContext } from "../../i18n";
import { getProfile, updateProfile } from "../../services/private";
import FormButtons from "../shared/FormButtons";
import { useCustomForm } from "../shared/Form";

interface ProfileComponentFormType {
  id?: string,
  email?: string,
  name?: string,
  system: any
}

const ProfileComponent = () => {
  const t = useI18nContext();
  const [ original, setOriginal ] = React.useState({});
  const [success, setSuccess] = React.useState<string>("");
  const { submit, form: { register, formState: { errors }, setValue, clearErrors, getValues }, 
    editing, setEditing, loading } = useCustomForm<ProfileComponentFormType>({
    defaultValues: { name: null, system: null },
    delay: 1500,
    submitFn: updateProfile,
    onSuccess: (_: any) => {
      setSuccess("general.toast.updated");
      setOriginal({ ...original, name: getValues("name") });
    }
  });
  React.useEffect(() => {
    (async () => {
      const result = await getProfile();
      if (result.success) {
        setValue("id", result.response.id);
        setValue("email", result.response.email);
        setValue("name", result.response.name);
        setOriginal(result.response);
      }
    })();
  }, []);
  React.useEffect(() => {
    if (success) {
      setTimeout(() => setSuccess(""), 2000);
    }
  }, [success, setSuccess]);
  const reset = React.useCallback(() => {
    setValue("name", original.name);
    setEditing(false);
    clearErrors();
  }, [ original, setValue, setEditing ]);
  return (
    <LayoutComponent>
      <Flex maxW="600px" direction="column" rowGap="20px">
        <h1 className="form-h1">{ t("private.sidebar.label.profile") }</h1>
        <FormControl isInvalid={ !!errors.system }>
            <FormErrorMessage>{ t(errors.system && errors.system.message) }</FormErrorMessage>
          </FormControl>
        <FormControl>
          <FormLabel>{ t("private.profile.label.id") }</FormLabel>
          <Input { ...register("id") } isDisabled={ true }></Input>
        </FormControl>
        <FormControl>
          <FormLabel>{ t("landing.label.email") }</FormLabel>
          <Input { ...register("email") } isDisabled={ true }></Input>
        </FormControl>
        <FormControl isInvalid={ !!errors.name }>
          <FormLabel>{ t("landing.label.name") }</FormLabel>
          <Input { ...register("name", { required: true  })} isDisabled={ !editing }></Input>
          <FormErrorMessage>{ t(errors.name && errors.name.message || "landing.error.name") }</FormErrorMessage>
        </FormControl>
        <FormButtons
          save={ submit }
          reset={ reset }
          edit={ editing }
          setEdit={ setEditing }
          saving={ loading }
          success={ success }
        />
      </Flex>
    </LayoutComponent>
    
  );
};

export default ProfileComponent;
