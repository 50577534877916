import { request } from "../base-request";

export default {};

interface loginAccountProps {
  email: string,
  password: string,
}

export const loginAccount = async (props: loginAccountProps) => {
  return await request({
    url: "/api/public/account/signin",
    method: "POST",
    data: props,
  });
};
