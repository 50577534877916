import { request } from "../base-request";

export default {};

export const signOut = async () => {
  return await request({
    url: "/api/private/account/signout",
    method: "POST"
  });
};
