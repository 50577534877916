import React from "react";
import { 
  Flex, 
  Button, 
  useColorMode
} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { signOut } from "../../../services/private";
import { useI18nContext } from "../../../i18n";

const NavComponent = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const signOutFn = React.useCallback(async () => {
    const response = await signOut();
    if (response.success) 
      window.location = window.location;
  }, []);
  const t = useI18nContext("private");
  return (
    <Flex h="60px" bg={ `private.navbar.bg.${ colorMode }` }>
      <Flex w="200px" alignItems="center" justifyContent="flex-start" pl="20px">
      <FontAwesomeIcon icon="fa-solid fa-laptop" className="button-icon" />
        AppLogo
      </Flex>
      <Flex grow="1"></Flex>
      <Flex columnGap="25px" pr="20px">
        <Flex alignItems="center">
          <Button variant="ghost" onClick={ signOutFn }>
            <FontAwesomeIcon icon="fa-solid fa-door-open" className="button-icon" />
            { t("nav.label.signout") }
          </Button>
        </Flex>
        <Flex alignItems="center">
          <Button variant="ghost" onClick={ toggleColorMode }>
            { colorMode == 'light' ? 
              <FontAwesomeIcon icon="fa-solid fa-moon" />
            :
              <FontAwesomeIcon icon="fa-solid fa-sun" />
            }
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default NavComponent;